import React, { createContext, useContext, useEffect, useState } from "react";
import { Avatar, Button, Drawer, InputNumber, List, notification } from "antd";

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cartValue, setCartValue] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [showCartList, setShowCartList] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState([]);
  const [branchDetail, setBranchDetail] = useState(null);
  const [pickupNote, setPickupNote] = useState(null);
  const [tipPrice, setTipPrice] = useState(20);
  const [totalPrice, setTotalPrice] = useState([]);
  const handleCartList = () => setShowCartList((prev) => !prev);

  // selected priduct details 

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedHight, setSelectedHight] = useState(null);
  const [selectedlength, setSelectedlength] = useState(null);
  const [selectedwidth, setSelectedwidth] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);
  /////====gestdetail====///

  // price state 

  const [amountDetails, setAmountDetials] = useState({
    totalAmount: "",
    subTotal: "",
    texAmount: "",
  });

  const [guestDetails, setGuestDetails] = useState(null);

  const updateGuestDetails = (details) => {
    setGuestDetails(details);
  };

  // Function to calculate total item price
  const totalItemPrice = () => {
    return cartValue.reduce(
      (total, product) => total + parseFloat(product.price * product.itemQty),
      0
    );
  };

  // Function to calculate sales tax
  const salesTextPrice = (itemTotal) => {
    return (itemTotal * 10) / 100;
  };

  // Function to calculate and update total price in state
  const calculateTotalPrice = () => {
    const total = totalItemPrice() + salesTextPrice(totalItemPrice()) + tipPrice;
    setTotalPrice(total); // Set the total price state
  };

  // ================= Check and Update Cart Data ============

  // useEffect(() => {
  //   const cartData = JSON.parse(localStorage.getItem("cart"));
  //   if (cartData !== null) {
  //     setCartValue(cartData);
  //     setCartCount(cartData.length);
  //   }
  // }, []);
  // ===================== Added Into Cart ===============
  const handleAddCart = (value) => {
    console.log("asdfghjkewqwerttreweghdff", value)
    if (cartValue && cartValue.length >= 0) {
      // Check if the item already exists in the cart
      const index = cartValue.findIndex(
        (filtValue) =>
          filtValue.colorid === selectedColor.product_color_id
      );
      if (index >= 0) {
        // If the item exists in the cart, update its quantity
        const previousData = [...cartValue]; // Create a copy of the cartValue array
        previousData[index].productQuantity = parseInt(cartValue[index].productQuantity) + value.Quantity;
        previousData[index].product_price = parseInt(cartValue[index].fixedPrice) * parseInt(cartValue[index].productQuantity);
        previousData[index].taxPrice = parseInt(cartValue[index].product_price * cartValue[index].product_tax) / 100
        setCartValue(previousData); // Update the state with the modified array
        addtoLocatStorage(previousData); // Storing into local storage
        openNotification(previousData[index], "Item Quantity Increase by 1");
      } else {
        const ProductData = {
          Weight: value.Weight,
          taxPrice: value.Quantity * ((value.productprice * selectedProduct.tax) / 100),
          Product_Height: selectedSize && selectedSize.Product_Height,
          Product_Width: selectedSize && selectedSize.Product_Width,
          Product_Length: selectedSize && selectedSize.Product_Length,
          product_tax: selectedProduct && selectedProduct.tax,
          productid: selectedProduct && selectedProduct.Product_Id,
          product_type: selectedProduct && selectedProduct.product_type,
          colorid: selectedColor && selectedColor.product_color_id,
          sizeid: selectedColor && selectedColor.Size_Id,
          productName: selectedProduct && selectedProduct.Product_Name,
          productSize: selectedSize && selectedSize.productSize,
          productColor: selectedColor && selectedColor.color_name,
          product_price: value.productfixedPrice,
          productDiscount: selectedColor && selectedColor.discountInPercent,
          productImage: selectedColor && selectedColor.images[0].product_image,
          productQuantity: value.Quantity.toString(),
          fixedPrice: value.productprice,
        }
        openNotification(ProductData, "Added into cart");
        // If the item is not in the cart, add it
        setCartValue([...cartValue, ProductData]); // Add the new item to the cart
        addtoLocatStorage([...cartValue, ProductData]); // Storing into local storage
        setCartCount(cartValue.length + 1); // Update the cart count
      }
    } else {
      const ProductData = {
        Weight: value.Weight,
        taxPrice: value.Quantity * ((value.productprice * selectedProduct.tax) / 100),
        product_tax: selectedProduct && selectedProduct.tax,
        id: selectedProduct && selectedProduct.Product_Id,
        Product_Height: selectedSize && selectedSize.Product_Height,
        Product_Width: selectedSize && selectedSize.Product_Width,
        Product_Length: selectedSize && selectedSize.Product_Length,
        product_type: selectedProduct && selectedProduct.product_type,
        colorid: selectedColor && selectedColor.product_color_id,
        sizeid: selectedColor && selectedColor.Size_Id,
        productName: selectedProduct && selectedProduct.Product_Name,
        productSize: selectedSize && selectedSize.productSize,
        productColor: selectedColor && selectedColor.color_name,
        product_price: value.productfixedPrice,
        productDiscount: selectedColor && selectedColor.discountInPercent,
        productImage: selectedColor && selectedColor.images[0].product_image,
        productQuantity: value.Quantity.toString(),
        fixedPrice: value.productprice,
      }
      openNotification(ProductData, "Added into cart");
      // If the cart is empty, add the item as the first item
      setCartValue([ProductData]);
      addtoLocatStorage([ProductData]); // Storing into local storage
      setCartCount(1);
    }

    // ============= Price Details ==================
    // if (cartValue && cartValue.length >= 0) {
    //   setCartValue((prevCartValue) => {
    //     let total = 0;

    //     prevCartValue.forEach((product) => {
    //       total += parseFloat(product.product_price);
    //     });

    //     // Calculate tax based on the determined tax rate
    //     const tax = parseFloat(totalTax.toFixed(2));
    //     const itemTotal = parseFloat(total.toFixed(2));
    //     const totalAdd = itemTotal + tax;
    //     const finalAmount = parseFloat(totalAdd.toFixed(2));

    //     // Use the callback function to ensure state is updated
    //     setAmountDetials((prevAmountDetails) => ({
    //       ...prevAmountDetails,
    //       texAmount: tax,
    //       subTotal: itemTotal,
    //       totalAmount: finalAmount,
    //     }));
    //     return prevCartValue;
    //   });
    // }
  };


  //   =================== Open Notiification ================
  const openNotification = (value, message) => {
    const key = `open${Date.now()}`;
    const listDesign = (
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={value.productImage} />}
            title={`${value.productName} ${value.productColor}`}
            description={`Price: ${value.product_price}  Qty: ${value.productQuantity} Weight:${value.Weight}`}
          />
        </List.Item>
      </List>
    );
    api.open({
      message: message,
      description: listDesign,
      key,
    });
  };
  //////branchdetail//
  const contextbrnchdata = (data) => {
    setBranchDetail(data);
    // You can include any other logic or state updates you need here
  };
  ////branch id to c =======
  const updateBranchId = (id) => {
    setBranchId(id);
  };

  const contextValue = {
    cartCount,
    handleCartList,
    handleAddCart,
    showCartList,
    cartValue,
    branchDetail,
    salesTextPrice,
    setBranchDetail,
    branchDetail,
    setBranchDetail,
    contextbrnchdata,
    guestDetails,
    setGuestDetails,
    updateGuestDetails,
    branchData,
    branchId,
    updateBranchId,
    // Removed updateBranchData because it's not defined,
    setCartValue,
    setCartCount,
    totalItemPrice,
    setShowCartList,
    totalPrice,
    setTipPrice,
    tipPrice,
    setTipPrice,
    selectedProduct,
    setSelectedProduct,
    setSelectedSize,
    setSelectedColor,
    setSelectedPrice,
    setSelectedImages,
    selectedSize,
    selectedColor,
    selectedPrice,
    selectedImages,
    amountDetails,
    setSelectedWeight,
    selectedWeight,
    setSelectedHight,
    selectedHight,
    setSelectedwidth,
    selectedwidth,
    selectedlength,
    setSelectedlength
  };
  //brnchdetai

  // =================== add to local Storage ==================

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  useEffect(() => {
    const cartdata = JSON.parse(localStorage.getItem("cart"))
    if (cartdata && cartdata !== null) {
      setCartValue(cartdata)
      setCartCount(cartValue.length)
      if (cartValue && cartValue.length >= 0) {
        setCartValue((prevCartValue) => {
          let total = 0;
          prevCartValue.forEach((product) => {
            total += parseFloat(product.product_price);
          });
          const totalTax = (total * 10) / 100;
          const tax = parseFloat(totalTax.toFixed(2));
          const itemTotal = parseFloat(total.toFixed(2));
          const totalAdd = itemTotal + tax;
          const finalAmount = parseFloat(totalAdd.toFixed(2));

          // Use the callback function to ensure state is updated
          setAmountDetials((prevAmountDetails) => ({
            ...prevAmountDetails,
            texAmount: tax,
            subTotal: itemTotal,
            totalAmount: finalAmount,
          }));
          return prevCartValue;
        });
      }
    }
  }, []);

  return (
    <CartContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
