const Testimonial = [
    {
        Name: "Arjun Singh",
        Des: "The versatility of the tote bag from Jutie Pie is unbelievable. It’s my go-to for work, shopping, and even weekends away. The craftsmanship is top-notch, and it still looks brand new after months of use.",
    },
    {
        Name: "Rohan Gupta",
        Des: "I purchased a set of dining chairs from Jutie Pie, and they are both comfortable and stylish. They were the perfect addition to my dining room, and guests always compliment their unique design.",
    },
    {
        Name: "Vivek Patel",
        Des: "The coffee table I bought from Jutie Pie is a masterpiece. Its modern design is exactly what our living room needed. It's not just a table but a piece of art that sparks conversations.",
    },
    {
        Name: "Nikhil Sharma",
        Des: "Every item from Jutie Pie’s home decor section adds a special touch. I bought several wall art pieces, and they all have vibrant colors and exceptional details. Highly recommend for anyone looking to spice up their home interiors.",
    },
    {
        Name: "Rajesh Kulkarni",
        Des: "The decorative ceramic bowls from Jutie Pie are simply stunning. They are a bold statement in our kitchen and are incredibly versatile, perfect for both everyday use and special occasions.",
    },
    {
        Name: "Priya Banerjee",
        Des: "The yoga mat from Jutie Pie has transformed my morning routine. It's super grippy and just the right thickness for comfort and stability. Plus, the design is beautiful – it actually motivates me to practice more often!",
    },
    {
        Name: "Anjali Joshi",
        Des: "I bought an entrance mat from Jutie Pie, and it’s both functional and stylish. It catches all the dirt, keeping my floors clean, and the quirky design brightens up my entryway.",
    },
    {
        Name: "Sunita Reddy",
        Des: "Every purchase from Jutie Pie, from bags to home decor, impresses me with its quality and aesthetic appeal. Their products are durable and exactly as described on the website. Definitely a go-to for all my home and accessory needs!",
    },
    {
        Name: "Meera Chatterjee",
        Des: "Jutie Pie’s customer service matched the high quality of their products. I had questions about the table I was interested in, and they provided all the details I needed with patience and expertise.",
    },
    {
        Name: "Kavita Krishnan",
        Des: "I’m always excited to see what new items Jutie Pie adds to their collection. Every piece I’ve purchased, from chairs to decorative vases, has added personality and charm to my home. Their blend of modern and classic styles is perfect.",
    },
]

export default Testimonial;