import logo from './logo.svg';
import './App.css';
import NavBar from './Component/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Page/HomePage';
import ProductList from './Page/ProductList';
import Productdetails from './Page/Productdetails';
import AboutPage from './Page/AboutPage';
import ContactPage from './Page/ContactPage';
import Account from "./Page/Account";
import CheckOutPage from './Page/CheckOutPage';
import Profiles from './Component/Profile/Profiles';
import SuccessPage from './Page/SuccessPage';
import AddAddress from './Page/AddAddress';
import ProductData from './Component/ProductData';
import PrivacyPolicy from "./Component/Policy/PrivacyPolicy";
import TermsConditions from './Component/Policy/TermsConditions';
import ReturnsPolicy from './Component/Policy/ReturnsPolicy';
import FailedPage from './Page/FailedPage';
import OrderPay from './Page/OrderPay';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';

function App() {

  const clientId = "413735203093-tt019nmdfqqkff5vf69lhskudqtph6m0.apps.googleusercontent.com";

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    };

    gapi.load('client:auth2', start);
  })

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/Product' element={<ProductList />} />
          <Route path='/Product/:slugs' element={<ProductList />} />
          {/* <Route path='/productDetails' element={<Productdetails />} /> */}
          <Route path='/About' element={<AboutPage />} />
          <Route path='/Contact' element={<ContactPage />} />
          <Route path="/account/:page" element={<Account />} />
          <Route path="/product-view/:id" element={<Productdetails />} />
          <Route path="/CheckOutPage" element={<CheckOutPage />} />
          <Route path='/Profiles' element={<Profiles />} />
          <Route path='/order-success/:id' element={<SuccessPage />} />
          <Route path='/order-failed/:id' element={<FailedPage />} />
          <Route path='/AddAddress' element={<AddAddress />} />
          <Route path='/OrderPay' element={<OrderPay />} />
          {/* {ProductData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.ProductSlug}
              element={<Productdetails/>}
            />
          ))} */}
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/TermsConditions' element={<TermsConditions />} />
          <Route path='/ReturnsPolicy' element={<ReturnsPolicy />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
