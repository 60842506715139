import React from 'react';
import "../../Css/YourOrder.css";
import { useCartContext } from '../../context/addToCart';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

const YourOrder = () => {
    const { token } = useSelector((state) => state.auth);

    const { cartValue, amountDetails } = useCartContext();

    // get payment details 

    // const paymentData = sessionStorage.getItem('payment_details');

    // console.log("paymentData.encrypted_data", paymentData.encrypted_data)
    return (
        <>
            <div className='YourOrder-Con-Main'>
                <div className='YourOrder-Con-head-Main'>
                    <h2>Your Order</h2>
                </div>
                <div className='YourOrder-Con-Title-Main'>
                    <h2>Product</h2>
                    <h2>Total</h2>
                </div>
                {
                    cartValue.map((elem, index) => {
                        console.log("elem", elem)
                        return (
                            <>
                                <div className='YourOrder-Con-details-Main'>
                                    <div className='YourOrder-Con-details-text'>
                                        <h2>{elem.productName}</h2>
                                        <span>
                                            x
                                        </span>
                                        <span>
                                            {elem.productQuantity}
                                        </span>
                                    </div>
                                    <h2>₹ {elem.product_price}</h2>
                                </div>
                            </>
                        )
                    })
                }
                <div className='YourOrder-Con-details-Main'>
                    <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Subtotal</h2>
                    </div>
                    <h2>₹ {amountDetails.subTotal}</h2>
                </div>
                {/* <div className='YourOrder-Con-details-Main'>
                    <h2>Shipping</h2>
                    <div className='YourOrder-Con-radio-text'>
                        <div className='YourOrder-Con-radio-text-con'>
                            <label for="html">Flat rate: <span>$20.00</span></label>
                            <input type="radio" id="html" name="fav_language" value="HTML" />
                        </div>
                        <div className='YourOrder-Con-radio-text-con'>
                            <label for="html">Local pickup: <span>$20.00</span></label>
                            <input type="radio" id="html" name="fav_language" value="HTML" />
                        </div>
                        <div className='YourOrder-Con-radio-text-con'>
                            <label for="html">Free shipping</label>
                            <input type="radio" id="html" name="fav_language" value="HTML" />
                        </div>
                    </div>
                </div>
                <div className='YourOrder-Con-details-Main'>
                    <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Total</h2>
                    </div>
                    <h2>₹ 1000.10</h2>
                </div> */}
                <div className='Product-details-Addedcart-btn'>
                    <div className='Product-details-Addedcart-btn-ViewCart'>

                        <form
                            name='redirect'
                            action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                            method="post"
                        >
                            <input type="hidden" name="encRequest" value={JSON.parse(sessionStorage.getItem('payment_details')).encrypted_data} />
                            <input type="hidden" name="access_code" value="AVGI52LA22BY71IGYB" />
                            <button type="submit">Proceed to Payment</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default YourOrder;