import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../Css/Productdetails.css";
import { FiMinus, FiPlus } from 'react-icons/fi';
import Footer from "../Component/Footer";
import { HiStar } from 'react-icons/hi';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link, useParams } from 'react-router-dom';
import { useCartContext } from '../context/addToCart';
import axios from 'axios';

const ReadMore = ({ text }) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => setIsReadMore(!isReadMore);

    return (
        <p className="text">
            {text ? (isReadMore ? text.slice(0, 200) : text) : null}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? '...Read more' : 'Show less'}
            </span>
        </p>
    );
};

const Productdetails = () => {
    const { id } = useParams();
    // const search = useLocation();
    // const Path = search.pathname;
    const {
        selectedProduct,
        setSelectedPrice,
        setSelectedSize,
        setSelectedProduct,
        setSelectedColor,
        setSelectedImages,
        selectedSize,
        selectedColor,
        selectedPrice,
        selectedImages,
        handleAddCart,
        setSelectedWeight,
        selectedWeight,
        setSelectedHight,
        selectedHight,
        setSelectedwidth,
        selectedwidth,
        selectedlength,
        setSelectedlength
    } = useCartContext();

    const [productQuantity, setProductQuantity] = useState(1);
    const [productPrice, setProductPrice] = useState(selectedColor ? selectedColor.product_price : 1);
    const [productIndex, setProductIndex] = useState(0);
    const [ctegoryId, setCategoryId] = useState(0);
    // Product List 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://jutiepie.in/api/product.php?action=singleproduc&id=${id}`
                );
                // console.log("responseData", response.data)
                const responseData = response.data.list[0];
                // console.log("responseData",responseData)
                setSelectedProduct(responseData);
                // console.log("responseData", responseData.Category_Id)
                setCategoryId(responseData.Category_Id)
                // Set default selected size and color
                const defaultSize = responseData.size[0];
                const defaultColor = defaultSize.colors[0];
                const defaultWeight = defaultSize.Weight;


                setSelectedSize(defaultSize);
                setSelectedColor(defaultColor);
                setSelectedImages(defaultColor.images);

                setSelectedWeight(defaultWeight)
                setSelectedWeight(defaultSize.Weight)

                setSelectedHight(defaultSize.Product_Height)
                setSelectedwidth(defaultSize.Product_Width)
                setSelectedlength(defaultSize.Product_Length)
                // console.log("defaultColor.images", defaultSize.Product_Length)
                setSelectedPrice(defaultColor.product_price);
                setProductPrice(defaultColor.product_price);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    setSelectedPrice(selectedColor && selectedColor.price)

    // Function to handle incrementing the quantity

    const handleIncrement = () => {
        setProductQuantity((prevQuantity) => prevQuantity + 1);
    };


    // Function to handle decrementing the quantity
    const handleDecrement = () => {
        if (productQuantity > 1) {
            setProductQuantity((prevQuantity) => prevQuantity - 1);
        }
    };


    const [selectedSizeBorder, setSelectedSizeBorder] = useState(null);
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);

    const handleSizeSelection = (size) => {
        // console.log("size", size)
        if (size && size.colors && size.colors.length > 0) {
            // console.log("sizecolors", size.colors[0])
            const defaultColor = size.colors[0];
            setSelectedSize(size);
            setSelectedWeight(size.Weight);
            setSelectedHight(size.Product_Height);
            setSelectedlength(size.Product_Length)
            setSelectedColor(defaultColor);
            setSelectedImages(defaultColor.images);
            setSelectedColorIndex(0);
            setProductIndex(0);

            // Update the product price based on the selected size, color, and quantity
            const updatedPrice = defaultColor.product_price;
            setSelectedPrice(updatedPrice);
            setProductPrice(updatedPrice);
            setSelectedSizeBorder(size.productSize);
        } else {
            setSelectedSize(size);
        }
    };

    const handleColorSelection = (colorImages, color, index) => {
        if (colorImages && color && typeof color.product_price !== 'undefined') {
            setSelectedColorIndex(index);
            setSelectedColor(color);
            setSelectedImages(colorImages);
            setProductIndex(0);
            const updatedPrice = color.product_price;
            setSelectedPrice(updatedPrice);
            setProductPrice(updatedPrice);
        }
    };


    // State variables for selected size, selected color, and product price

    const handleImagechange = (index) => {
        setProductIndex(index);
    };

    // console.log("selectedProduct", selectedSize)

    const [productData, setProductsData] = useState([])
    console.log(productData)    
    const fetchData = async () => {
        try {
            const response = await axios.get(`https://jutiepie.in/api/product.php?&action=productFetch&category_id=${ctegoryId}`);
            setProductsData(response.data.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [ctegoryId]);


    return (
        <>
            <section className='Product-Detailspage-con'>
                <div className='container'>
                    <div className='Prodct-details-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='ProductColor-Full-Image-con'>
                                    <div className='Product-Details-Imagechange-con'>
                                        <div className='Product-Details-Imagechange'>
                                            {selectedImages && selectedImages[productIndex] && selectedImages[productIndex].product_image && (
                                                <img src={selectedImages[productIndex].product_image} alt='Selected Product' />
                                            )}
                                        </div>
                                    </div>
                                    <div className='Product-Color-Image-main-con'>
                                        {selectedImages && selectedImages.length > 1 &&
                                            selectedImages.map((product_image, index) => (
                                                <div
                                                    key={index}
                                                    className='product-Color-Image-con'
                                                    onClick={() => handleImagechange(index)}
                                                    style={{
                                                        border: index === productIndex ? '2px solid black' : 'none',
                                                        opacity: index === productIndex ? 1 : 0.6,
                                                        cursor: 'pointer',
                                                        transition: 'all 0.3s ease'
                                                    }}
                                                >
                                                    {product_image && product_image.product_image && (
                                                        <img src={product_image.product_image} alt={`Color ${index + 1}`} />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Product-Details-Info-Main-Con'>
                                    <div className='Product-Details-Info-Main-Con'>
                                        <h4>{selectedProduct && selectedProduct.Product_Name}</h4>
                                        <div className='Product-Details-Info-Para'>
                                            <span>PLENTY OF STORAGE:</span>
                                            <p>
                                                <p>
                                                    {selectedProduct ? (
                                                        <ReadMore text={selectedProduct.Product_Desc} />
                                                    ) : (
                                                        <span>Loading description...</span>
                                                    )}
                                                </p>
                                            </p>
                                        </div>
                                        <div className='Product-Details-Info-price'>
                                            <div className="Product-Card-Details-price">
                                                <h4>₹ {productPrice} /-
                                                    {/* <span>4,000/- Rs</span> */}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className='Product-Deatils-Size-con'>
                                            <div className='Product-Deatils-Size-con-head'>
                                                <h5>
                                                    Size (cm):
                                                </h5>
                                            </div>
                                            <div className='Product-Details-Size-con-Main'>
                                                <div className='Product-Details-Size-con-Main'>
                                                    <div className='Product-Details-Size-con-Main'>
                                                        <div className='Product-Details-Size-con-Main'>
                                                            <div className='Product-Details-Size-con-Main'>
                                                                <div className='Product-Details-Size-con-Main'>
                                                                    {selectedProduct &&
                                                                        selectedProduct.size.map((size) => {
                                                                            return (
                                                                                <div
                                                                                    key={size.Product_Height}
                                                                                    className={`Product-Details-Size-con-text-con ${selectedSize && selectedSize.Product_Height === size.Product_Height || selectedSize && selectedSize.Product_Width === size.Product_Width || selectedSize && selectedSize.Product_Length === size.Product_Length ? 'selected active-button' : ''}`}
                                                                                    onClick={() => handleSizeSelection(size)}
                                                                                    style={{
                                                                                        border: selectedSize && selectedSize.Product_Height === size.Product_Height || selectedSize && selectedSize.Product_Width === size.Product_Width || selectedSize && selectedSize.Product_Length === size.Product_Length ? '2px solid #821F40' : 'none', // Red border for selected size
                                                                                    }}
                                                                                >
                                                                                    <h2>{size.Product_Width}'W' X {size.Product_Height}'H' X {size.Product_Length} 'L'</h2>
                                                                                </div>
                                                                            );
                                                                        })}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedWeight && (
                                            <div className='Producr-Details-Weight-con'>
                                                <div className='Product-Details-Weight-con-head'>
                                                    <h5>Weight</h5>
                                                </div>
                                                <div className='Product-Details-Weight-con-Main'>
                                                    <div className='Product-Details-Weight-details'>
                                                        <h5>
                                                            {selectedWeight > 999
                                                                ? `${(selectedWeight / 1000).toFixed(1)} kg`
                                                                : `${selectedWeight} g`}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className='Product-Deatils-color-con'>
                                            <div className='Product-Deatils-color-con-head'>
                                                <h5>Color:</h5>
                                            </div>
                                            <div className='Product-Details-color-con-Main'>
                                                {selectedSize &&
                                                    selectedSize.colors &&
                                                    selectedSize.colors.map((color, i) => (
                                                        <>
                                                            <div className='product-deatils-color-flex'>
                                                                <div
                                                                    key={i}
                                                                    className={`Product-Details-color ${selectedColorIndex === i ? 'selected active-button' : ''}`}
                                                                    style={{
                                                                        backgroundColor: color.color_code,
                                                                        border: selectedColorIndex === i ? '3px solid #821F40' : 'none', // Add active border style
                                                                    }}
                                                                    onClick={() => handleColorSelection(color.images, color, i)} // Pass the index to the handler
                                                                ></div>
                                                                <h6>{color.color_name}</h6>
                                                            </div>
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className='Product-deatils-Quantity-main-con'>
                                            <div className='Product-deatils-Quantity-head'>
                                                <h6>Quantity</h6>
                                                <div className='Product-deatils-Quantity-main-con2'>
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <div className="Product-details-Quantity-counter">
                                                                <div className='Product-details-Quantity-counter-material'>
                                                                    <button onClick={handleDecrement}>
                                                                        <FiMinus />
                                                                    </button>
                                                                    <h3>{productQuantity}</h3>
                                                                    <button onClick={handleIncrement}>
                                                                        <FiPlus />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div className='Productdetails-Page-AddtoCart-con'>
                                                                <div className='Productdetails-Page-AddtoCart'>
                                                                    <button onClick={() => handleAddCart({
                                                                        // productName: selectedProduct && selectedProduct.productcategory,
                                                                        // size: selectedSize,
                                                                        // color: selectedColor,
                                                                        productprice: productPrice,
                                                                        // ProductImage: selectedProduct && selectedProduct.ProductImage01,
                                                                        Quantity: productQuantity,
                                                                        Weight: selectedWeight && selectedWeight,
                                                                        productfixedPrice: selectedColor && selectedColor.product_price * productQuantity
                                                                    })}>
                                                                        Add To Cart
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='Product-details-page-buy-btn-con'>
                                                    <button>
                                                        Buy Now
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            {/* /////////////////////////// Produst des ///////////////////////////////// */}

            < section className='Product-Details-Related-Products-con' >
                <div className='container'>
                    <div className='product-details-Related-Products-Head-com'>
                        <div className='product-details-Related-Products-Head'>
                            <h2 className='Smalltitle'>Next Day Products</h2>
                            <h3 className='Headtitle'>Related Products</h3>
                        </div>
                    </div>
                    <div className='Product-Details-Related-Products-main'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {
                                productData && productData.map((elem) => {

                                    return (
                                        <>
                                            < SwiperSlide >
                                                <div className="Filter-Card-body-con">
                                                    <Link to={`/product-view/${elem.Product_Id}`}>
                                                        <div className='Filter-Card-body'>
                                                            <div className='Filter-Card-body-Image-con'>
                                                                <div className='Filter-Card-body-Image'>
                                                                    <img src={elem.size[0].colors[0].images[0].product_image} />
                                                                </div>
                                                            </div>
                                                            <div className='Filter-Card-Details-Main-con'>
                                                                <div className='Filter-Card-Details-Main'>
                                                                    <h2>{elem.product_type}</h2>
                                                                    <h3>{elem.Product_Name}</h3>
                                                                    <div className='Filter-Card-Details-Star'>
                                                                        <HiStar />
                                                                        <HiStar />
                                                                        <HiStar />
                                                                        <HiStar />
                                                                        <HiStar />
                                                                    </div>
                                                                    <div className='Filter-Card-Details-price'>
                                                                        <h4>{elem.size[0].colors[0].product_price}/- Rs</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}

export default Productdetails;
