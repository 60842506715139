import React from 'react';
import "../Css/AddAddress.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GoPlus } from "react-icons/go";
import Footer from "../Component/Footer";
const AddAddress = () => {
    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='-Section-1'>
                <div className='container'>

                    <div className='OrderAddress-Section-con'>
                        <div className='OrderAddress-Section-Text'>
                            <h2>
                                Order Address
                            </h2>
                            <div className='OrderAddress-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h2>Order Address</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AddAddress