import React, { useEffect, useState } from 'react';
import "../Css/SuccessPage.css";
import { PiSealCheckFill } from "react-icons/pi";
import deliverymanriding from "../Images/delivery-man-riding.png";
import { RxCross2 } from "react-icons/rx";
import ProductData from '../Component/ProductData';
import { useCartContext } from '../context/addToCart';
import EmptyComp from ".././Component/Empty/empty";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
const SuccessPage = () => {

    const { cartValue, setCartCount, amountDetails, setCartValue } = useCartContext();

    const { id } = useParams();

    const removeCartData = (index) => {
        const prevData = cartValue;
        prevData.splice(index, 1);
        addtoLocatStorage(prevData);
        setCartCount(prevData.length);
    };

    useEffect(()=>{
        setCartCount(0);
        setCartValue([]);
        localStorage.setItem("cart", JSON.stringify([]));
    })

    // =================== add to local Storage ==================

    const addtoLocatStorage = (data) => {
        localStorage.setItem("cart", JSON.stringify(data));
    };


    const [orderProduct, setOrderProduct] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://jutiepie.in/api/order-product.php?action=fetchOrderList&order_id=' + id);
            setOrderProduct(response.data.data[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
        setCartCount(0);
        setCartValue([]);
        localStorage.setItem("cart", JSON.stringify([]));
        localStorage.clear()
    }, [id]);

    // console.log("Order Product", orderProduct)


    return (
        <>
            <section className='SuccessPage-Section-con'>
                <div className='container'>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <PiSealCheckFill />
                                    <h2>Order Confirmed</h2>
                                    <p>Congratulations! Your order with Juite Pie has been successfully confirmed. We appreciate your business and are thrilled to fulfill your delicious cravings. Expect a delightful experience as our team prepares and dispatches your order with care. Thank you for choosing Juite Pie – where flavor meets satisfaction.</p>
                                </div>
                                <div className='Order-Success-Details-con-Main'>
                                    <div className='Order-Success-Details-con-Main-head'>
                                        <div className='Order-Success-List-head'>
                                            <h3>Order Id : - <span>{orderProduct && orderProduct.order_id}</span></h3>
                                        </div>
                                        <div className='Order-Success-List-head'>
                                            <h3>Ordered on : - <span>{orderProduct && moment(orderProduct.created_date).format("llll")}</span></h3>
                                        </div>
                                    </div>
                                    <div className='Order-Success-Details-con'>
                                        <div className='Order-Success-Details-add'>
                                            <h1>delivering to :</h1>
                                            <div className='Order-Success-Details-name-contact'>
                                                <h2>
                                                    {orderProduct && orderProduct.User_Name}
                                                </h2>
                                                <span>
                                                    |
                                                </span>
                                                <h2>
                                                    {orderProduct && orderProduct.User_Phone}
                                                </h2>
                                            </div>
                                            <div className='Order-Success-Details-address'>
                                                <p>{orderProduct && orderProduct.address_1}, {orderProduct && orderProduct.address_2}, {orderProduct && orderProduct.city}</p>
                                            </div>
                                        </div>
                                        <div className='Order-Success-Details-Image-con'>
                                            <img src={deliverymanriding} alt="" />
                                        </div>
                                    </div>
                                </div>


                                <div className='Order-Success-List-con'>
                                    <div class="Order-Success-List">
                                        <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                            {
                                                orderProduct && orderProduct.products.map((elem, index) => {
                                                    // console.log("first", elem)
                                                    const { size, image, color } = elem
                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details'>
                                                                <div className='Product-addedCart-Image-con'>
                                                                    <div className='Product-addedCart-Image'>
                                                                        <img src={image[0].product_image} />
                                                                    </div>
                                                                </div>
                                                                <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                    <div className='Product-details-addedCart-price'>
                                                                        <h2>{elem.Product_Name}</h2>
                                                                        <h3><span>Color : </span>{elem.color_name}</h3>
                                                                        <h3><span>Size : </span>{elem.Product_Height} x {elem.Product_Width}</h3>
                                                                    </div>
                                                                    {/* <h4>{elem.product_price}/- Rs</h4> */}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='Product-details-Addedcart-total-con'>
                                            <div className='Product-details-Addedcart-total'>
                                                <div className='Product-details-Addedcart-total-title'>
                                                    <h4>Total:</h4>
                                                </div>
                                                <div className='Product-details-Addedcart-total-Amount'>
                                                    <h5>{orderProduct && orderProduct.order_amount} /- Rs</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-success-page-btn-con'>
                                            <Link to="/">
                                                <button>
                                                    Back to home
                                                </button>
                                            </Link>
                                            <Link to="/Product">
                                                <button>
                                                    Continue Shopping
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessPage