import React, { useEffect, useState } from 'react';
import "../Category/CategoryPage.css";
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from 'react-router-dom';

const CategoryPage = () => {

    const [data, setData] = useState([]);
    const Productshort = data.slice(0, 8);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://jutiepie.in/api/category.php?&action=categoryFetch');
                setData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <section className='Home-Section-2'>
                <div className='container'>
                    <div className='Home-Section-2-con'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                Productshort.map((ele) => {
                                    const { Category_Img, Category_Slugs, Category_Type } = ele
                                    return (
                                        <>
                                            < SwiperSlide >
                                                <div className='Home-Section2-card-body-con'>
                                                    <Link to={`/Product/${Category_Slugs}`}>
                                                        <div className='Home-Section2-card-body'>
                                                            <div className='Home-Section3-Card-Image-con'>
                                                                <div className='Home-Section3-Card-Image'>
                                                                    <img src={Category_Img} />
                                                                </div>
                                                            </div>
                                                            <div className='Home-Section-2-CardName'>
                                                                <h2>{Category_Type}</h2>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CategoryPage