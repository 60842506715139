import React from 'react';
import { useSelector } from 'react-redux';
import OrderDeatils from '../Component/checkout/OrderDeatils';
import OrderAddress from '../Component/checkout/OrderAddress';
import Orderlogin from '../Component/checkout/Orderlogin';
import Successbtn from '../Component/successandfailed/Successbtn';
import YourOrder from '../Component/checkout/YourOrder';
import PayAddress from '../Component/checkout/PayAddress';

const OrderPay = () => {
    const { token } = useSelector((state) => state.auth);
    return (
        <>
            <section className='CheckOutPage-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            {token ? (
                                <>
                                    <OrderDeatils />
                                    <PayAddress />
                                </>
                            ) : (
                                <Orderlogin />
                            )
                            }
                        </div>
                        <div className='col-md-5'>
                            <YourOrder />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderPay