import React, { useEffect, useMemo, useState } from 'react';
import "../Order/MyOrder.css";
import ProductImage01 from "../../Images/Products/Basket-PNG-Image-HD.png"
import { HiStar } from 'react-icons/hi';
import { useCartContext } from '../../context/addToCart';
import { RxCross2 } from "react-icons/rx";
import { Drawer } from 'antd';
import { Button, ConfigProvider, Popover, Segmented } from 'antd';
import axios from 'axios';
import { Pagination } from 'antd';
import moment from 'moment';
const MyOrder = () => {

    const { cartValue } = useCartContext();
    const tokenid = sessionStorage.getItem("access_token")

    const [open, setOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const showDrawer = (order) => {
        setOrderDetails(order);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const Content = ({ address_1, address_2, state, city, pincode }) => (
        <div className='Order-Details-Address'>
            <p>{address_1}</p>
            <p>{address_2}</p>
            <p>{city}, {state} {pincode}</p>
        </div>
    );
    // const text = <span>Title</span>;
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);


    const [yourOrderProduct, setYourOrderProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // State to manage current page
    const itemsPerPage = 4; // Number of items to display per page

    console.log("yourOrderProduct", yourOrderProduct)

    const fetchData = async () => {
        try {
            const response = await axios.get('https://jutiepie.in/api/order-product.php?action=fetchOrderList&token=' + tokenid);
            setYourOrderProduct(response.data.data);
            console.log("response.data.data",response.data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [tokenid]);


    // Logic to paginate the orders
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = yourOrderProduct && yourOrderProduct.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <section className='MyOrder-Section'>
                <div className='MyOrder-Section-Heading'>
                    <h1>All Order</h1>
                </div>

                <div className='MyOrder-Section-con'>
                    {currentItems === null ? (
                        <p>Looks like you haven't placed an order</p>
                    ) : (
                        <>
                            {currentItems && currentItems.map((elem, index) => {

                                const { User_Email, User_Name, User_Phone, address_1, address_2, address_id, alternative_number, city, state, created_date, order_amount, order_id, order_list_id, order_status, payment_mode, pincode, products, tracking_id, user_id
                                } = elem
                                return (
                                    <>
                                        <div className='Product-addedCart-Side-Bar orderlistmaincon'>
                                            <div className='Orderlist-Details-Head-Con'>
                                                <div className='row'>
                                                    <div className='col-md-2 col-sm-4 col-12'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Order Placed</h2>
                                                            <h3>{moment(created_date).format("llll")}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 col-sm-4 col-6'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Total</h2>
                                                            <h3>{order_amount}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 col-sm-4 col-6'>
                                                        <div className='OrderList-Details-Date'>
                                                            <h2>Ship To</h2>
                                                            <h3>
                                                                <Popover placement="bottom" content={<Content address_1={address_1} address_2={address_2} city={city} state={state} pincode={pincode} />} arrow={mergedArrow}>
                                                                    <Button>{User_Name}</Button>
                                                                </Popover>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div className='OrderList-Details-Date orderidcon'>
                                                            <h2>ORDER {order_id}</h2>
                                                            <div className='OrderList-Status-Details'>
                                                                <div className='OrderList-Status-Details-btn'>
                                                                    {order_status}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                products && products.map((elem) => {
                                                    const { Category_Id, Product_Code, Product_Desc, Product_Height, Product_Id, Product_Name, Product_Width, Size_Id, color_code, color_name, created_at, product_color_id, product_feature, product_price, product_qty, image } = elem
                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details orderList-con'>
                                                                <div className='Product-details-addedCart-price-name order-details-addedCart-price-name'>
                                                                    <h3><span>Name : </span>{Product_Name}</h3>
                                                                    <h3><span>Quantity : </span>{product_qty}</h3>
                                                                    <h3><span>Price : </span>{product_price}/- Rs</h3>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='Product-details-addedCart-cancel-btn'>
                                                <div className='Product-details-addedCart-cancel-btn-con OrderProduct-Btn-Con'>
                                                    <button onClick={() => showDrawer(elem)}>
                                                        View order details
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </>
                    )}
                    <Pagination
                        defaultCurrent={1}
                        total={yourOrderProduct ? yourOrderProduct.length : 0} // Total number of items
                        pageSize={itemsPerPage} // Items per page
                        onChange={(page) => setCurrentPage(page)} // Function to handle page change
                    />
                </div>
            </section>

            <Drawer title="Order Details" onClose={onClose} open={open}>
                {orderDetails && (
                    <section className='Order-Details-Drawer'>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>Ordet Date</h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>{moment(orderDetails.created_date).format("llll")}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>Ordet </h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>{orderDetails.order_id}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='Order-Details-First-Part'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='Order-DetailsName'>
                                            <h2>Ordet total</h2>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='Order-Details-dec'>
                                            <h3>Rs. {orderDetails.order_amount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Shipment details</h2>
                            </div>
                            {
                                orderDetails.products && orderDetails.products.map((elem) => {
                                    const { Category_Id, Product_Code, Product_Desc, Product_Height, Product_Id, Product_Name, Product_Width, Size_Id, color_code, color_name, created_at, product_color_id, product_feature, product_price, product_qty, image } = elem
                                    return (
                                        <>
                                            <div className='Order-details-Drawer-Shipment-details'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className='Order-details-Drawer-Product-Image'>
                                                            <img src={image[0].product_image} />
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='Order-details-Drawer-Product-Details'>
                                                            <h3>{Product_Name}</h3>
                                                            <h4>Qty: <span>{product_qty}</span></h4>
                                                            <h4>Price: <span>{product_price}</span></h4>
                                                            <h4>color: <span>{color_name}</span></h4>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Payment Methods</h2>
                                <p>Pay On Delivery</p>
                            </div>
                            <div className='Order-details-Drawer-Shipment-details'>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <h3>{orderDetails.User_Name}</h3>
                                    <p>{orderDetails.address_1}</p>
                                    <p>{orderDetails.address_2}</p>
                                    <p>{orderDetails.city}, {orderDetails.state}, {orderDetails.pincode}</p>
                                </div>
                            </div>
                        </div>
                        <div className='Order-Details-Drawer-Details-order-Id'>
                            <div className='Order-Details-Drawer-HeadDetails'>
                                <h2>Order Summary</h2>
                            </div>
                            <div className='Order-details-Drawer-Shipment-details'>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Subtotal:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.subtotal}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Delivery Charges:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.delivery_charges}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3>Tax:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4>{orderDetails.tax_amount}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Order-details-Drawer-Product-Details'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='Order-details-Drawer-payment-details'>
                                                <h3 className='Order-details-Drawer-OrderTotal'>Total Amount:</h3>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='Order-details-Drawer-payment-number'>
                                                <h4 className='Order-details-Drawer-Orderamount'>{orderDetails.order_amount}/- Rs</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Drawer>
        </>
    )
}

export default MyOrder;