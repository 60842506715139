import React from 'react'

const PayAddress = () => {


    return (
        <>
            <div className='OrderDetials-con OrderAddresscon'>
                <div className='OrderDetials-billing-personal-details-con'>
                    <div className='OrderDetials-billing-personal-details'>
                        <h2>Shipping Details</h2>
                        <div className='OrderAddresscon-personal-deatails'>
                            {/* Display selected address */}
                            {/* {payment_Address && (
                                <div className='OrderAddresscon-personal-deatails-selected'>
                                    <p>
                                        {payment_Address.address_1},<br />
                                        {payment_Address.address_2}<br />
                                        {payment_Address.city}, {payment_Address.pincode},<br />
                                        {payment_Address.state},<br />
                                        Phone Number: {payment_Address.alternative_number}
                                    </p>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayAddress