import React, { useEffect, useState } from 'react';
import "../AddressList/Addresspage.css";
import { FiPlus } from "react-icons/fi";
import { Modal, message, Select } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';

const { Option } = Select;

const Addresspage = () => {
    const { token } = useSelector(state => state.auth);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        address_1: "",
        address_2: "",
        pincode: "",
        alternative_number: "",
        city: "",
        stateCode: "",
        stateName: ""
    });
    const [formErrors, setFormErrors] = useState({
        address_1: "",
        address_2: "",
        pincode: "",
        alternative_number: "",
        city: "",
        stateCode: "",
        stateName: ""
    });
    const [userAddress, setUserAddress] = useState([]);

    useEffect(() => {
        fetchData();
    }, [token]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (Object.values(formData).every(value => value.trim() !== "")) {
            const formDataToSend = new FormData();
            formDataToSend.append("action", "addAddress");
            formDataToSend.append("token", token);
            
            // Append all fields from formData to formDataToSend
            Object.keys(formData).forEach(key => {
                console.log("state",formData)
                formDataToSend.append(key, formData[key]);
            });
            try {
                const response = await axios.post("https://jutiepie.in/api/address.php", formDataToSend);
                if (response.data && response.data.status === "success") {
                    setUserAddress(prev => [...prev, formData]);
                    message.success("Address Added Successfully");
                    setIsModalOpen(false);
                    setFormData({
                        address_1: "",
                        address_2: "",
                        pincode: "",
                        alternative_number: "",
                        city: "",
                        stateCode: "",
                        stateName: ""
                    });
                } else {
                    message.error("Failed to add the address");
                }
            } catch (error) {
                console.error("Error sending data to the API:", error);
                message.error("Error adding address");
            }
        } else {
            updateFormErrors();
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target || e;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
        setFormErrors(prev => ({
            ...prev,
            [id]: value.trim() ? "" : "This field is required"
        }));
    };

    const handleStateChange = (value) => {
        const [stateCode, stateName] = value.split(" - ");
        setFormData(prev => ({
            ...prev,
            stateCode,
            stateName
        }));
        setFormErrors(prev => ({
            ...prev,
            stateCode: "",
            stateName: ""
        }));
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://jutiepie.in/api/address.php?action=fetchAddresses&token=${token}`);
            setUserAddress(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onRemoveAddress = async (address_id) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("action", "deleteAddress");
            formDataToSend.append("token", token);
            formDataToSend.append("address_id", address_id);

            const response = await axios.post("https://jutiepie.in/api/address.php", formDataToSend);
            if (response.data && response.data.status) {
                setUserAddress(prevAddresses => prevAddresses.filter(address => address.address_id !== address_id));
                message.success("Address Removed Successfully");
            } else {
                message.error("Failed to remove the address");
            }
        } catch (error) {
            console.error('Error removing address:', error);
            message.error("Error removing address");
        }
    };

    const updateFormErrors = () => {
        const errors = {};
        Object.keys(formData).forEach(key => {
            errors[key] = formData[key].trim() ? "" : "This field is required";
        });
        setFormErrors(errors);
    };

    return (
        <>
            <section className='ProfileEdit'>
                <div className='ProfileEdit-con'>
                    <div className='ProfileEdit-con-heading'>
                        <h2>Your Addresses</h2>
                    </div>
                    <div className='YourAddresses-main-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='YourAddresses-card-add' onClick={showModal}>
                                    <div className='YourAddresses-card-body-add'>
                                        <FiPlus />
                                        <h3>Add Address</h3>
                                    </div>
                                </div>
                            </div>
                            {userAddress.map(elem => (
                                <div className='col-md-6' key={elem.address_id || elem.pincode}>
                                    <div className='YourAddresses-card'>
                                        <div className='YourAddresses-card-body'>
                                            <div className='YourAddresses-card-Para'>
                                                <p>{elem.address_1}, {elem.address_2}, {elem.city}, {elem.pincode}, {elem.stateName}</p>
                                                <h3>Phone Number: <span>{elem.alternative_number}</span></h3>
                                            </div>
                                            <div className='YourAddresses-card-Para-btn'>
                                                <button className='YourAddresses-border-remove' onClick={() => onRemoveAddress(elem.address_id)}>Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Modal title="Add a new address" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <section className='OrderAddress-Popup-Section'>
                    <div className='OrderAddress-Form-MainCon'>
                        <form>
                            <div className="form-group">
                                <label htmlFor="address_1">Address 1</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.address_1 ? "is-invalid" : ""}`}
                                    id="address_1"
                                    value={formData.address_1}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="invalid-feedback">{formErrors.address_1}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="address_2">Address 2</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.address_2 ? "is-invalid" : ""}`}
                                    id="address_2"
                                    value={formData.address_2}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="invalid-feedback">{formErrors.address_2}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="pincode">Pincode</label>
                                <input
                                    type="number"
                                    className={`form-control ${formErrors.pincode ? "is-invalid" : ""}`}
                                    id="pincode"
                                    value={formData.pincode}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="invalid-feedback">{formErrors.pincode}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="alternative_number">Alternative Number</label>
                                <input
                                    type="number"
                                    className={`form-control ${formErrors.alternative_number ? "is-invalid" : ""}`}
                                    id="alternative_number"
                                    value={formData.alternative_number}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="invalid-feedback">{formErrors.alternative_number}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.city ? "is-invalid" : ""}`}
                                    id="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="invalid-feedback">{formErrors.city}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <Select
                                    placeholder="Select State"
                                    className={`form-control ${formErrors.stateCode ? "is-invalid" : ""}`}
                                    id="state"
                                    onChange={handleStateChange}
                                    required
                                >
                                    <Option value="01 - Jammu and Kashmir">01 - Jammu and Kashmir</Option>
                                    <Option value="02 - Himachal Pradesh">02 - Himachal Pradesh</Option>
                                    <Option value="03 - Punjab">03 - Punjab</Option>
                                    <Option value="04 - Chandigarh">04 - Chandigarh</Option>
                                    <Option value="05 - Uttarakhand">05 - Uttarakhand</Option>
                                    <Option value="06 - Haryana">06 - Haryana</Option>
                                    <Option value="07 - Delhi">07 - Delhi</Option>
                                    <Option value="08 - Rajasthan">08 - Rajasthan</Option>
                                    <Option value="09 - Uttar Pradesh">09 - Uttar Pradesh</Option>
                                    <Option value="10 - Bihar">10 - Bihar</Option>
                                    <Option value="11 - Sikkim">11 - Sikkim</Option>
                                    <Option value="12 - Arunachal Pradesh">12 - Arunachal Pradesh</Option>
                                    <Option value="13 - Nagaland">13 - Nagaland</Option>
                                    <Option value="14 - Manipur">14 - Manipur</Option>
                                    <Option value="15 - Mizoram">15 - Mizoram</Option>
                                    <Option value="16 - Tripura">16 - Tripura</Option>
                                    <Option value="17 - Meghalaya">17 - Meghalaya</Option>
                                    <Option value="18 - Assam">18 - Assam</Option>
                                    <Option value="19 - West Bengal">19 - West Bengal</Option>
                                    <Option value="20 - Jharkhand">20 - Jharkhand</Option>
                                    <Option value="21 - Odisha">21 - Odisha</Option>
                                    <Option value="22 - Chattisgarh">22 - Chattisgarh</Option>
                                    <Option value="23 - Madhya Pradesh">23 - Madhya Pradesh</Option>
                                    <Option value="24 - Gujarat">24 - Gujarat</Option>
                                    <Option value="26 - Dadra and Nagar Haveli and Daman and Diu">26 - Dadra and Nagar Haveli and Daman and Diu</Option>
                                    <Option value="27 - Maharashtra">27 - Maharashtra</Option>
                                    <Option value="29 - Karnataka">29 - Karnataka</Option>
                                    <Option value="30 - Goa">30 - Goa</Option>
                                    <Option value="31 - Lakshadweep">31 - Lakshadweep</Option>
                                    <Option value="32 - Kerala">32 - Kerala</Option>
                                    <Option value="33 - Tamil Nadu">33 - Tamil Nadu</Option>
                                    <Option value="34 - Puducherry">34 - Puducherry</Option>
                                    <Option value="35 - Andaman and Nicobar Islands">35 - Andaman and Nicobar Islands</Option>
                                    <Option value="36 - Telangana">36 - Telangana</Option>
                                    <Option value="37 - Andhra Pradesh">37 - Andhra Pradesh</Option>
                                </Select>
                                <div className="invalid-feedback">{formErrors.stateCode}</div>
                            </div>
                        </form>
                    </div>
                </section>
            </Modal>
        </>
    );
};

export default Addresspage;
