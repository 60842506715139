import React from 'react';
import { HiStar } from 'react-icons/hi';
import { FiShoppingCart } from 'react-icons/fi';
import { Link } from 'react-router-dom';
// import { useProductContext } from '../Context/ProContext';
import brokenimages from "../Images/no-image.png";
const Productcart = (
    { product }
) => {


    const ProductImage = product.size && product.size[0].colors && product.size[0].colors[0].images && product.size[0].colors[0].images[0];
    const Productprice = product.size && product.size[0].colors && product.size[0].colors[0].product_price;
    const displayImage = ProductImage ? ProductImage.product_image : brokenimages;
    // const { addToCartFunction } = useProductContext();

    const handleAddToCart = () => {
        // console.log("product", product); // Print the product array in the console
    };
    // console.log("product data", product);
    return (
        <>
            <Link to={`/product-view/${product.Product_Id}`}>
                <div className="Filter-Card-body-con">
                    <div className='Filter-Card-body'>
                        <div className='Filter-Card-body-Image-con'>
                            <div className='Filter-Card-body-Image'>
                                <img src={displayImage} />
                            </div>
                            <div className='Filter-Card-side-body-con'>
                                <div className='Filter-Card-side-icons-con'>
                                    <div className='Filter-Card-side-icons'>
                                        <FiShoppingCart
                                            onClick={handleAddToCart}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='Filter-Card-Details-Main-con'>
                            <div className='Filter-Card-Details-Main'>
                                <h2>{product.product_type}</h2>
                                <h3>{product.Product_Name}</h3>
                                <div className='Filter-Card-Details-price'>
                                    <h4>{Productprice} ₹
                                        {/* <span>{product.productofferprice} ₹</span> */}
                                    </h4>
                                </div>
                                <div className='Filter-Card-Details-Star'>
                                    <HiStar />
                                    <HiStar />
                                    <HiStar />
                                    <HiStar />
                                    <HiStar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default Productcart