import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../Css/HomePage.css";
import "../Css/Item.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Footer from "../Component/Footer";
import JuteBags from "../Images/bag/bag_01-removebg-preview (1).png";
import JuteCarpet from "../Images/bag/jute color carpet.png";
import JuteFurniture from "../Images/bag/Jute_furniture-removebg-preview.png";
import JuteCraft from "../Images/bag/jute_craft-removebg-preview.png";
import { HiStar } from 'react-icons/hi';
import { BsArrowRightShort } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';
import { PiCurrencyDollarLight } from 'react-icons/pi';
import { CiDiscount1 } from 'react-icons/ci';
import { LiaTruckMovingSolid, LiaHeadphonesSolid } from 'react-icons/lia';
import ProductData from '../Component/ProductData';
import Productcart from './Productcart';
import { IoCalendarOutline } from "react-icons/io5";
// Section call 
import CategoryPage from '../Component/Category/CategoryPage';
import HomeProduct from '../Component/Produdct/HomeProduct';
import HomeFavorite from '../Component/FavoriteProduct/HomeFavorite';
import Testimonial from '../Component/Testimonial';
///////////
import demotestimonial from "../Images/demo-removebg-preview.png";
import { Link } from 'react-router-dom';

const HomePage = () => {

    // const Productshort = ProductData.slice(0, 8);
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='Home-Section-01'>
                <div className='Home-Banner-Section'>
                    <div className="homebanner1">
                        {/* <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="bannerDesc">
                                        <small>Welcome to Jutie Pie</small>
                                        <h2>Handcrafted Elegance – Uniquely Yours</h2>
                                        <p>
                                            Each piece boasts unique, artisanal craftsmanship, blending tradition and modern style seamlessly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* ---------------------------- Section 2 --------------------------- */}

            <CategoryPage />

            {/* ------------------- Section 3 -------------------- */}

            {/* <HomeProduct /> */}


            {/* ------------------- Section 6 -------------------- */}

            {/* <section className='Home-Section-6'>
                <div className='container'>
                    <div className='Home-Section-6-con'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <LiaTruckMovingSolid />
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Delivery</h2>
                                        <h3>Within 7-10 working days</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <IoCalendarOutline />
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Return & Exchange</h2>
                                        <h3>Easy and hassle free</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <CiDiscount1 />
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Member Discount</h2>
                                        <h3>On Every order over ₹ 140.00</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <LiaHeadphonesSolid />
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Support 24/7</h2>
                                        <h3>We Are avilable for our customer</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* ------------------- Section 4 -------------------- */}

            <HomeFavorite />
            {/* ------------------- Section 5 -------------------- */}

            <section className='Home-Section-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='home-Section-5-offer1-con'>
                                <div className='home-Section-5-offer1-details'>
                                    <div className='home-Section-5-offer1-details-text'>
                                        <h2 className='Smalltitle'>Nature’s Craft, Your Style: Jutie Pie</h2>
                                        <h3 className='Headtitle'>Bringing Nature’s Touch to Your Space</h3>
                                        <div className='more-btn-con'>
                                            <Link to="/product-view/1">
                                                <button className='more-btn'>
                                                    Shop Now <BsArrowRightShort />
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='home-Section-5-offer2-con'>
                                <div className='home-Section-5-offer2-details'>
                                    <div className='home-Section-5-offer2-details-text'>
                                        <h3>Feel the Natural Elegance with Jutie Pie</h3>
                                        <h2>From Nature, For Home: Jutie Pie</h2>
                                        <div className='more-btn-con'>
                                            <Link to="/product-view/44">
                                                <button className='more-btn'>
                                                    Shop Now <BsArrowRightShort />
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////// Section 7 ////////////////////// */}

            <section className='Home-Section-7'>
                <div className='container'>
                    <div className='Home-Section-3-head-con'>
                        {/* <h2 className='Smalltitle'>All Product Shop</h2> */}
                        <h3 className='Headtitle'>Customer Favorite Style Product</h3>
                    </div>
                    <div className='Home-Section-7-testimonial'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                Testimonial.map((elem) => {
                                    const { Name, Des } = elem

                                    return (
                                        <>
                                            < SwiperSlide >
                                                <div
                                                    className="slick-single-layout testimonial-style-one slick-slide slick-current slick-active"
                                                    data-slick-index={0}
                                                    aria-hidden="false"
                                                    tabIndex={0}
                                                // style={{ width: 384 }}
                                                >
                                                    <div className="review-speech">
                                                        <p>
                                                            “{Des}“
                                                        </p>
                                                    </div>
                                                    <div className="media">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={demotestimonial}
                                                                alt="testimonial image"
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            {/* <span className="designation">Head Of Idea</span> */}
                                                            <h6 className="title">{Name}</h6>
                                                        </div>
                                                    </div>
                                                    {/* End .thumbnail */}
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default HomePage