import React from 'react';
import "../../Css/Profiles.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaUserEdit } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import ProfileEdit from './ProfileEdit';
import Footer from "../Footer";
import MyOrder from '../Order/MyOrder';
import Addresspage from '../AddressList/Addresspage';
const Profiles = () => {
    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Profiles-Section-1'>
                <div className='container'>

                    <div className='Profiles-Section-con'>
                        <div className='Profiles-Section-Text'>
                            <h2>
                                Account
                            </h2>
                            <div className='Profiles-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none"></h1>
                                <h2>Account</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ------------------------ Section 2 ---------------------------- */}
            <section className='Profile-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <div className='Profile-Section-Sidebar'>
                                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><FaUserEdit />Profile</button>
                                    <button class="nav-link" id="v-pills-MyOrder-tab" data-bs-toggle="pill" data-bs-target="#v-pills-MyOrder" type="button" role="tab" aria-controls="v-pills-MyOrder" aria-selected="false"><FaBoxOpen />My Order</button>
                                    <button class="nav-link" id="v-pills-Addresses-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Addresses" type="button" role="tab" aria-controls="v-pills-Addresses" aria-selected="false"><MdLocationOn />Delivery Addresses</button>
                                    {/* <button class="nav-link" id="v-pills-Help-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Help" type="button" role="tab" aria-controls="v-pills-Help" aria-selected="false"><BsFillPatchQuestionFill />Need Help</button>
                                    <button class="nav-link" id="v-pills-FAQ-tab" data-bs-toggle="pill" data-bs-target="#v-pills-FAQ" type="button" role="tab" aria-controls="v-pills-FAQ" aria-selected="false"><FaClipboardList />FAQ</button> */}
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8 col-md-12'>
                            <div className='Profile-Section-Tabs'>
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><ProfileEdit /></div>
                                    <div class="tab-pane fade" id="v-pills-MyOrder" role="tabpanel" aria-labelledby="v-pills-MyOrder-tab"><MyOrder /></div>
                                    <div class="tab-pane fade" id="v-pills-Addresses" role="tabpanel" aria-labelledby="v-pills-Addresses-tab"><Addresspage /></div>
                                    {/* <div class="tab-pane fade" id="v-pills-Help" role="tabpanel" aria-labelledby="v-pills-Help-tab">Need Help</div>
                                    <div class="tab-pane fade" id="v-pills-FAQ" role="tabpanel" aria-labelledby="v-pills-FAQ-tab">FAQ</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Profiles