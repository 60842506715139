import React, { useEffect, useState } from "react";
import "../../Css/YourOrder.css";
import { useCartContext } from "../../context/addToCart";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { paymentdata } from "../../services/localStorageServices";
import { useNavigate } from "react-router-dom";
import { setProfile } from "../../services/localStorageServices";
import { getdeliveryaddress } from "../../services/localStorageServices";
import { Button, message } from 'antd';

const PayOrder = ({ selectedAddress }) => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { cartValue, setCartCount, setCartValue } = useCartContext();

  const [productId, setProductId] = useState([]);
console.log("selectedAddress addsbchabkjcnsncksjn",selectedAddress && selectedAddress.id)

  const profileData = JSON.parse(sessionStorage.getItem('profile'));


  useEffect(() => {
    if (cartValue.length > 0) {
      const productData = cartValue.map(item => ({
        product_id: item.productid,
        color_id: item.colorid,
        size_id: item.sizeid,
        product_qty: item.productQuantity,
        product_price: item.product_price
      }));
      setProductId(productData);
    }
  }, [cartValue]);

  const removeCartData = (index) => {
    setCartValue((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      addtoLocatStorage(updatedData);
      setCartCount(updatedData.length);
      return updatedData;
    });
  };

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  // Function to generate a random order ID
  const generateOrderId = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let orderId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      orderId += characters.charAt(randomIndex);
    }
    return orderId;
  };


  let dateTime = new Date();
  dateTime = dateTime.getTime();
  const [address, setAddress] = useState(null)
  console.log("final address", address)
  const tokenid = sessionStorage.getItem("access_token")
  const fetchDataAdd = async () => {
    try {
      const response = await axios.get('https://jutiepie.in/api/address.php?&action=fetchAddresses&token=' + tokenid);

      const addressListData = response.data.data;
      const selected = addressListData.filter(address => address.action === "1")
      setAddress(selected[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // console.log("selected", address)
  useEffect(() => {
    fetchDataAdd();
    generateTransactionId()
  }, [tokenid]);


  const generateTransactionId = () => {
    // Generate transaction ID based on current date and time
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Create a transaction ID with a length of exactly 17 characters
    let transactionId = year.toString() +
      month +
      day +
      hours +
      minutes +
      seconds;

    // Ensure transaction ID length is exactly 17 characters
    const remainingLength = 17 - transactionId.length;
    if (remainingLength > 0) {
      transactionId += Math.floor(Math.random() * (10 ** remainingLength)).toString();
    } else if (remainingLength < 0) {
      transactionId = transactionId.substring(0, 17); // Trim if exceeds 17 characters
    }

    return transactionId;
  };

  // const clearData = () => {
  //   setCartCount(0);
  //   setCartValue([]);
  //   localStorage.setItem("cart", JSON.stringify([]));
  // }
  console.log("cartValue", cartValue)
  const error = () => {
    if (!tokenid) {
      message.error('Please log in first to place the order.');
    } else if (selectedAddress == null) {
      message.error('Please select a delivery address.');
    }
  };


  useEffect(() => {
    calculateDeliveryCharges(cartValue)
  }, [cartValue]);

  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const calculateDeliveryCharges = (cartValue) => {
    let charges = 0;
    let subtotal = 0;

    cartValue.forEach(item => {
      subtotal += parseFloat(item.product_price) * item.productQuantity;

      if (item.product_type === "chair") {
        charges += 250 * item.productQuantity;
      } else {
        const weightInKg = (parseFloat(item.Weight) / 1000) * item.productQuantity;
        if (weightInKg <= 1) {
          charges += 100;
        } else {
          charges += weightInKg * 100;
        }
      }
    });

    // Check if subtotal is 5000 or more
    if (subtotal >= 5000) {
      charges = 0; // Free delivery charges
    }

    return charges;
  };


  // Calculate delivery charges when cartValue changes
  useEffect(() => {
    const charges = calculateDeliveryCharges(cartValue);
    setDeliveryCharges(Math.round(charges));
  }, [cartValue]);



  let finalsubtotal = 0;
  cartValue.forEach(product => {
    Math.round(finalsubtotal += parseFloat(product.product_price));
  });

  // Calculate totaltax
  let totaltax = 0;
  cartValue.forEach(product => {
    Math.round(totaltax += parseFloat(product.taxPrice));
  });

  // Calculate totalamount
  let totalamount = finalsubtotal + totaltax + deliveryCharges;


  return (
    <>
      <div className="YourOrder-Con-Main">
        <div className="YourOrder-Con-head-Main">
          <h2>Your Order</h2>
        </div>
        <div className="YourOrder-Con-Title-Main">
          <h2>Product</h2>
          <h2>Total</h2>
        </div>
        <div className="Product-addedCart-Side-Bar">
          {cartValue.map((elem, index) => (
            <div className="Product-addedCart-Side-details" key={index}>
              <div className="Product-addedCart-Image-con">
                <div className="Product-addedCart-Image">
                  <img src={elem.productImage} alt={elem.productName} />
                </div>
              </div>
              <div className="Product-details-addedCart-price-name">
                <h2>
                  {elem.productName} ({elem.Weight &&
                    elem.Weight > 999 &&
                    `${(elem.Weight / 1000).toFixed(1)} kg`
                  }
                  {elem.Weight &&
                    elem.Weight <= 999 &&
                    `${elem.Weight} g`
                  })
                </h2>
                <h3>
                  <span>Size : </span>
                  {elem.Product_Height}'H' X {elem.Product_Width}'W' X {elem.Product_Length}'L'
                </h3>
                <h3>
                  <span>Quantity : </span>
                  {elem.productQuantity}
                </h3>
                <h3>
                  <span>Price : </span>
                  {elem.product_price} ₹
                </h3>
              </div>
              <div className="Product-details-addedCart-cancel-btn">
                <div
                  className="Product-details-addedCart-cancel-btn-con"
                  onClick={() => removeCartData(index)}
                >
                  <RxCross2 />
                </div>
              </div>
            </div>
          ))}
          <div className='YourOrder-Con-details-Main'>
            <div className='YourOrder-Con-details-text'>
              <h2 className='YourOrder-totoal'>Subtotal</h2>
            </div>
            <h2>₹ {finalsubtotal}</h2>
          </div>
          <div className='YourOrder-Con-details-Main'>
            <div className='YourOrder-Con-details-text'>
              <h2 className='YourOrder-totoal'>Delivery Charges</h2>
            </div>
            <h2>₹ {deliveryCharges === 0 ? 'Free' : deliveryCharges}</h2>
          </div>
          <div className='YourOrder-Con-details-Main'>
            <div className='YourOrder-Con-details-text'>
              <h2 className='YourOrder-totoal'>Tax</h2>
            </div>
            <h2>₹ {totaltax}</h2>
          </div>
          <div className='YourOrder-Con-details-Main'>
            <div className='YourOrder-Con-details-text'>
              <h2 className='YourOrder-totoal'>Total Amount</h2>
            </div>
            <h2>₹ {Math.round(totalamount)}</h2>
          </div>
          <div className="product-details-palce-order-details">
            <h4>Free delivery above 5000 </h4>
            <h5>7 working days to reach your doorstep </h5>
          </div>
        </div>
        <div className="Product-details-Addedcart-btn">
          <div className="Product-details-Addedcart-btn-ViewCart">
            {tokenid && selectedAddress ? ( // If tokenid exists
              <form
                method="post"
                name="customerData"
                action="https://jutiepie.in/api/payment-request.php"
              >
                <input
                  type="hidden"
                  name="token"
                  value={sessionStorage.getItem("access_token")}
                />
                <input
                  type="hidden"
                  name="tid"
                  value={generateTransactionId()}
                  readOnly
                />
                <input type="hidden" name="merchant_id" value="3207884" />
                <input
                  type="hidden"
                  name="order_id"
                  value={generateOrderId(20)}
                />
                <input
                  type="hidden"
                  name="address_id"
                  value={selectedAddress && selectedAddress.address_id}
                />
                <input
                  type="hidden"
                  name="state_code"
                  value={selectedAddress && selectedAddress.address_id}
                />
                <input
                  type="hidden"
                  name="all_id"
                  value={cartValue && JSON.stringify(productId)}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={Math.round(totalamount)}
                />
                <input
                  type="hidden"
                  name="delivery_charges"
                  value={deliveryCharges}
                />
                <input
                  type="hidden"
                  name="tax_amount"
                  value={totaltax}
                />
                <input
                  type="hidden"
                  name="subtotal"
                  value={finalsubtotal}
                />
                <input type="hidden" name="currency" value="INR" />
                <input
                  type="hidden"
                  name="redirect_url"
                  value="https://jutiepie.in/api/paymnet-response.php"
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  value="https://jutiepie.in/api/paymnet-response.php"
                />
                <input type="hidden" name="language" value="EN" />
                <input type="hidden" name="billing_name" value={profileData && profileData.username} />
                <input
                  type="hidden"
                  name="billing_address"
                  value={`${selectedAddress && selectedAddress.address_1} ${selectedAddress && selectedAddress.address_2}`}
                />
                <input type="hidden" name="billing_city" value={selectedAddress && selectedAddress.city} />
                <input type="hidden" name="billing_state" value={selectedAddress && selectedAddress.stateName} />
                <input type="hidden" name="billing_state_code" value={selectedAddress && selectedAddress.stateCode} />
                <input type="hidden" name="billing_zip" value={selectedAddress && selectedAddress.pincode} />
                <input type="hidden" name="billing_country" value="India" />
                <input type="hidden" name="billing_tel" value={selectedAddress && selectedAddress.alternative_number} />
                <input type="hidden" name="billing_email" value={profileData && profileData.email} />
                <input type="hidden" name="delivery_name" value={profileData && profileData.username} />
                <input
                  type="hidden"
                  name="delivery_address"
                  value={`${selectedAddress && selectedAddress.address_1} ${selectedAddress && selectedAddress.address_2}`}
                />
                <input type="hidden" name="delivery_city" value={selectedAddress && selectedAddress.city} />
                <input type="hidden" name="delivery_state" value={selectedAddress && selectedAddress.stateName} />
                <input type="hidden" name="delivery_state_code" value={selectedAddress && selectedAddress.stateCode} />
                <input type="hidden" name="delivery_zip" value={selectedAddress && selectedAddress.pincode} />
                <input type="hidden" name="delivery_country" value="India" />
                <input type="hidden" name="delivery_tel" value={selectedAddress && selectedAddress.alternative_number} />

                <button type="submit">Place Your Order and Pay</button>
              </form>
            ) : (
              <button onClick={error}>Place Your Order and Pay</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayOrder;
