import React, { useLayoutEffect } from 'react';
import "../Css/ContactPage.css";
import Footer from "../Component/Footer";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import contact from "../Images/customer-service.png";
import map from "../Images/map.png";
import SocialMedia from "../Images/social-media.png";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { Button, Form, Input } from 'antd';
import $ from "jquery";

const ContactPage = () => {
    const [form] = Form.useForm();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const onFinish = (values) => {
        submitForm(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const submitForm = (values) => {
        // console.log("value", values)
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">Juite Pie</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Email Id:</strong></td><td style="text-align:left">' + values.email + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Juite Pie</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "daddu.tamhankar01@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "jutiepie",
            accountLeadSource: "https://jutiepie.in/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        form.resetFields();
        values.preventDefault()
        return false;

    }

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Contact-Section-1'>
                <div className='container'>

                    <div className='Contact-Section-con'>
                        <div className='Contact-Section-Text'>
                            <h2>
                                Contact
                            </h2>
                            <div className='Contact-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                                <h2>Contact</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 1 ---------------------- */}
            <section className='Contact-Section-2'>
                <div className='container'>
                    <div className='Contact-Section-2-con'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Contact-Section-2-Heading'>
                                    <h2>Contact Us</h2>
                                </div>
                                <div className='Contact-page-Section-form'>
                                    <Form
                                         form={form}
                                         layout='vertical'
                                         onFinish={onFinish}
                                         onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            label="Your Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your name" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Your Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                    message: 'Please input a valid email address!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your email" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Contact"
                                            name="contact"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your contact number!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your contact number" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Your Message"
                                            name="message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your message!',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea rows={4} placeholder="Enter your message" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit">
                                                Send Message
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='Contact-Page-Details-con'>
                                    <div className='Contact-Page-Icons-Info'>
                                        <div className='Contact-Page-Icons'>
                                            <img src={contact} />
                                        </div>
                                        <div className='Contact-Page-Info'>
                                            <h2><a href="mailto: jutiepie99@gmail.com">jutiepie99@gmail.com</a></h2>
                                            <h3><a href="tel:+917304384413">+91 73043 84413</a></h3>
                                            <h3><a href="tel:+918779691413">+91 87796 91413</a></h3>
                                        </div>
                                    </div>
                                    <div className='Contact-Page-Icons-Info'>
                                        <div className='Contact-Page-Icons'>
                                            <img src={map} />
                                        </div>
                                        <div className='Contact-Page-Info'>
                                            <h2><a>22, Nalanda shopping centre, Bandu gore marg, Near goregaon station, Goregaon west, Mumbai 400104</a></h2>
                                        </div>
                                    </div>
                                    <div className='Contact-Page-Icons-Info'>
                                        <div className='Contact-Page-Icons'>
                                            <img src={SocialMedia} />
                                        </div>
                                        <div className='Contact-Page-Info'>
                                            <h2><a>Find on social media</a></h2>
                                            <div className='ContactPage-Social-Media'>
                                                <span><a href='https://www.facebook.com/jutiepiee/' target='blank'><FaFacebookF /></a></span>
                                                <span><a href='https://www.instagram.com/jutie_pie/' target='blank'><FaInstagram /></a></span>
                                                <span><a href="https://wa.me/+917304384413?text=" target='blank'><IoLogoWhatsapp /></a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ContactPage